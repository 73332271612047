<template>
  <layout :title="$route.name">
    <div class="expande-horizontal wrap">
      <v-flex xs12>
        <div style="background: #fff;" class="expande-horizontal centraliza">
          <v-btn
            :text="filterDate !== 'all'"
            @click="setFilterDate('all')"
            color="black"
            dark
            x-small
            class="fonte-bold mr-1"
          >
            Todos
          </v-btn>
          <v-btn
            :text="filterDate !== '7'"
            @click="setFilterDate('7')"
            color="black"
            dark
            x-small
            class="fonte-bold mr-1"
          >
            7 dias
          </v-btn>
          <v-btn
            :text="filterDate !== '15'"
            @click="setFilterDate('15')"
            color="black"
            dark
            x-small
            class="fonte-bold mr-1"
          >
            15 dias
          </v-btn>
          <v-btn
            :text="filterDate !== '30'"
            @click="setFilterDate('30')"
            color="black"
            dark
            x-small
            class="fonte-bold mr-1"
          >
            30 dias
          </v-btn>
        </div>
      </v-flex>
      <v-tabs color="black" v-model="tabs" show-arrows>
        <v-tab class="fonte-bold">Tudo</v-tab>
        <v-tab class="fonte-bold">Receb. Bonificação</v-tab>
        <v-tab class="fonte-bold">Ped. Saque</v-tab>
        <v-tab class="fonte-bold">Depósito</v-tab>
        <v-tab class="fonte-bold">Compras</v-tab>
        <v-tab class="fonte-bold">Transferências</v-tab>
      </v-tabs>
      <v-flex xs12>
        <div class="expande-horizontal">
          <v-list style="min-width: 100%;" class="pa-0 ma-0" three-line>
            <template v-for="(item, index) in filteredTransactions">
              <v-list-item @click="drawCanvas(item)" :key="item._id">
                <v-list-item-content>
                  <v-list-item-title class="fonte-bold">
                    {{ $helper.formataSaldo(item.value) }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="fonte">
                    <v-chip
                      x-small
                      style="border-radius: 6px;"
                      :color="getStatus(item.current_status).color"
                    >
                      <span class="white--text fonte-bold">{{
                        getStatus(item.current_status).text
                      }}</span>
                    </v-chip>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ item.description }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    class="font-weight-light"
                    style="font-size: 8pt;"
                  >
                    {{
                      $moment(item.created_at)
                        .locale("pt-br")
                        .format("llll")
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="index"></v-divider>
            </template>
          </v-list>
        </div>
      </v-flex>

      <Empty v-if="filteredTransactions.length === 0"></Empty>
    </div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.smAndDown"
      v-model="modalOpen"
      width="400"
    >
      <div
        class="expande-horizontal column"
        style="background: #e5e5e5; height: 600px;"
      >
        <div class="expande-horizontal">
          <v-list-item class="px-2" style="border-bottom: 1px solid #999;">
            <v-avatar size="40" class="mr-3 elevation-1" color="#fff">
              <v-btn icon @click="modalOpen = false">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="fonte fonte-bold"
                >Extrato</v-list-item-title
              >
              <v-list-item-subtitle class="fonte"
                >Visualização de comprovante</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </div>
        <div class="expande-horizontal column" style="position: relative;">
          <img :src="selectedTransaction.imageData" alt="" />
          <div style="position: absolute; bottom: 15px; right: 15px;">
            <v-avatar class="mr-2 elevation-3" color="#333">
              <v-btn
                icon
                color="blue"
                @click="drawCanvas(selectedTransaction, true)"
              >
                <v-icon color="#f2f2f2">mdi-share-variant</v-icon>
              </v-btn>
            </v-avatar>
          </div>
        </div>
      </div>
    </v-dialog>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      tabs: 0,
      filterDate: "all",
      modalOpen: false,
      selectedTransaction: {}
    };
  },
  computed: {
    ...mapGetters(["getTransactions"]),
    filteredTransactions() {
      const now = this.$moment();

      const filterByDays = days => {
        return this.getTransactions.filter(item => {
          let created_at = this.$moment(item.created_at);
          let daysInPast = now.clone().subtract(days, "days");
          return created_at.isBetween(daysInPast, now, null, "[]"); // Inclusivo de início e fim
        });
      };

      let filteredsByDate = [];

      switch (this.filterDate) {
        case "7":
          filteredsByDate = filterByDays(7);
          break;
        case "15":
          filteredsByDate = filterByDays(15);
          break;
        case "30":
          filteredsByDate = filterByDays(30);
          break;
        case "60":
          filteredsByDate = filterByDays(60);
          break;
        case "90":
          filteredsByDate = filterByDays(90);
          break;
        case "all":
          filteredsByDate = this.getTransactions;
          break;
        default:
          filteredsByDate = this.getTransactions;
          break;
      }

      const types = {
        0: "all",
        1: "bonification",
        2: "withdraw",
        3: "deposit",
        4: "sale",
        5: "transfer"
      };

      const allowedTypes = types[this.tabs];
      if (allowedTypes === "all") {
        return filteredsByDate;
      }
      const filteredByType = filteredsByDate.filter(
        item => item[`${allowedTypes}`]
      );

      return filteredByType.reverse();
    }
  },
  methods: {
    openModal(transaction) {
      this.selectedTransaction = transaction; // Armazena a transação clicada
      this.modalOpen = true; // Abre o modal
    },
    drawCanvas(transaction, share) {
      // const canvas = this.$refs.canvas;
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      // Definindo tamanho do canvas
      const width = 800;
      const height = 600;
      canvas.width = width;
      canvas.height = height;
      // Criar background com um gradiente para simular papel envelhecido
      const gradient = context.createLinearGradient(0, 0, 0, height);
      gradient.addColorStop(0, "#e5e5e5"); // cor mais clara no topo
      gradient.addColorStop(1, "#f2f2f2"); // cor mais escura na base

      // Preencher o fundo com o gradiente
      context.fillStyle = gradient;
      context.fillRect(0, 0, width, height);

      // Adicionar efeitos de "desgaste" no papel
      for (let i = 0; i < 350; i++) {
        const x = Math.random() * width;
        const y = Math.random() * height;
        const radius = Math.random() * 5;
        context.beginPath();
        context.arc(x, y, radius, 0, 2 * Math.PI);
        context.fillStyle = `rgba(0, 0, 0, ${Math.random() * 0.2})`; // cor marrom aleatória e translúcida
        context.fill();
      }

      // Adicionar bordas escuras para simular desgaste nas extremidades
      // context.strokeStyle = "rgba(0, 0, 0, 1)";
      // context.lineWidth = 10;
      // context.radius = 20;
      // context.strokeRect(0, 0, width, height);

      // Desenhar um retângulo branco translúcido por cima para o texto se destacar
      // context.fillStyle = "rgba(255, 255, 255, 0.0)";
      // context.fillRect(0, 0, width, height);
      // Carregar a imagem de fundo
      const backgroundImage = new Image();
      backgroundImage.src = "/logo_mini.png"; // Caminho relativo à pasta "public"
      backgroundImage.onload = () => {
        context.drawImage(backgroundImage, 0, 6, 130, 130);
        // Desenhar o texto por cima da imagem de fundo
        context.fillStyle = "#000";
        context.font = "40px Arial";
        context.fillText(
          `${this.$helper.formataSaldo(transaction.value)}`,
          20,
          180
        );
        context.font = "20px Arial";
        context.fillText(`${transaction.description}`, 20, 220);
        context.strokeStyle = "#333";
        context.lineWidth = 1;
        context.strokeRect(20, 250, width, 0);

        context.fillText(
          `${this.$moment(transaction.created_at).format("LLLL")}`,
          20,
          320
        );
        context.strokeStyle = "#333";
        context.lineWidth = 1;
        context.strokeRect(20, 250, width, 0);

        context.fillText(`${transaction._id}`, 20, 400);
        context.strokeStyle = "#333";
        context.lineWidth = 1;
        context.strokeRect(20, 250, width, 0);

        let imageSrc = "";
        imageSrc = canvas.toDataURL("image/png");
        // Gerando a imagem a partir do canvas
        if (!share) {
          this.openModal({
            ...transaction,
            imageData: imageSrc
          });
        } else {
          // Converte a base64 para um arquivo Blob
          const byteString = atob(imageSrc.split(",")[1]);
          const mimeString = imageSrc
            .split(",")[0]
            .split(":")[1]
            .split(";")[0];
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);

          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }

          const blob = new Blob([ab], { type: mimeString });
          const file = new File([blob], "comprovante.png", {
            type: "image/png"
          });

          if (navigator.vibrate) navigator.vibrate(200);

          if (navigator.canShare && navigator.canShare({ files: [file] })) {
            navigator
              .share({
                title: "Comprovante da Transação",
                text: `Detalhes da transação`,
                files: [file]
              })
              .then(() => console.log("Compartilhamento bem-sucedido!"))
              .catch(error => console.error("Erro ao compartilhar:", error));
          } else {
            // Fallback: Exibir um link para download ou copiar para a área de transferência
            const link = document.createElement("a");
            link.href = imageSrc;
            link.download = "comprovante.png";
            link.click();
          }
        }
      };
      // const canvas = document.getElementById('myCanvas'); // O ID do seu canvas

      // Compartilhando
      // this.shareCanvas(this.imageSrc);
    },
    shareWhats(transaction) {
      // Compartilhando no WhatsApp (como link de imagem)
      const url = `https://wa.me/?text=Veja%20os%20detalhes%20da%20transação:%20${encodeURIComponent(
        transaction.imageData
      )}`;
      window.open(url, "_blank");
    },
    // shareViaWhatsApp(transaction) {
    //   const message = `Transação do tipo ${transaction.type} com valor de ${transaction.value}`;
    //   const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    //     message
    //   )}`;
    //   window.open(whatsappUrl, "_blank"); // Abre o link de compartilhamento
    // },
    shareViaTelegram(transaction) {
      const message = `Transação do tipo ${transaction.type} com valor de ${transaction.value}`;
      const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(
        message
      )}`;
      window.open(telegramUrl, "_blank"); // Abre o link de compartilhamento
    },
    setFilterDate(date) {
      this.filterDate = date;
    },
    ...mapActions(["listTransactions"]),
    getStatus(status) {
      switch (status) {
        case "waiting":
          return {
            text: "Aguardando",
            color: "orange"
          };
        case "active":
          return {
            text: "Disponível",
            color: "green"
          };
        case "requested":
          return {
            text: "Não consolidada",
            color: "red"
          };
        default:
          return {
            text: "Outros",
            color: "black"
          };
      }
    }
  },
  created() {
    this.listTransactions();
  }
};
</script>
